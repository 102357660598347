export default {
  locationsPage: {
    '.locationsContainer': {
      margin: ['0.25rem 0rem', '', '0.5rem 0.25rem']
    },
    '.logo': {
      height: 'unset',
      position: 'static',
      transform: 'unset',
      padding: ['0.5rem', '', '1rem'],
      backgroundColor: 'black',
      img: {
        maxHeight: ['60px', '75px']
      }
    },
    '.location': {
      margin: '0.25rem 0.5rem'
    },
    '.CTAButton': {
      display: 'none'
    },
    '.locationTitle': {
      marginBottom: '1rem',
      fontSize: '2rem'
    },
    '.enterSiteButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================
  navigation: {
    // Container
    '.container': {
      backgroundColor: 'rgba(0,0,0,0.65)',
      padding: ['', '', '', '1rem 2rem'],
      display: 'flex',
      justifyContent: 'center',
      '.smallNavMenu': {
        // backgroundColor: '#ffffffba',s
        padding: '0.5rem',
        '> div': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          a: {
            color: 'white',
            fontWeight: '400',
            textTransform: 'uppercase'
          }
        },
        '.reservations': {
          a: {
            padding: '0.5rem 1rem',
            color: 'white',
            backgroundColor: 'primary',
            borderRadius: '50px',
            fontWeight: 'bold'
          }
        }
      },
      color: 'black'
    },

    '.containerScrolled': {
      backgroundColor: 'rgba(0,0,0,0.65)',
      color: 'text',
      padding: '0rem',
      boxShadow: '0px 0px 24px 0px rgb(0 0 0 / 15%)',
      borderBottom: 'none',
      '.smallNavMenu': {
        '> div': {
          borderColor: 'black',
          a: {
            color: 'white',
            fontWeight: '400',
            textTransform: 'uppercase'
          }
        },
        '.reservations': {
          a: {
            padding: '0.5rem 1rem',
            color: 'white',
            backgroundColor: 'primary',
            borderRadius: '50px',
            fontWeight: 'bold'
          }
        }
      }
    },

    // hamburger
    '.hamburger': {
      borderRadius: '10px',
      background: 'none',
      border: 'none',
      padding: '0.75rem',
      '> div': {
        backgroundColor: 'secondary',
        height: '2px'
      },
      'div:nth-of-type(2)': {
        width: '70%'
      }
    },
    'li.navItem': {
      a: {
        color: 'white',
        fontFamily: 'body',
        // fontSize: ['1.7rem', '1.7rem', '1.7rem', '1.7rem', '1.7rem'],
        fontWeight: 'bold',
        textTransform: 'capitalize'
      }
    },

    '.logoLocationContainer': {
      position: 'static',
      margin: '0rem auto 0rem 0rem'
    },
    '.logo': {
      img: {
        filter: 'unset',
        maxHeight: '60px'
      }
    },
    '.logoScrolled': {
      img: {
        // filter: 'brightness(0) invert(1)',
        maxHeight: '50px'
      }
    },
    '.navMenuLogo': {
      // variant: 'customVariants.borderCorners',
      padding: '3rem 0rem',
      img: {
        maxHeight: ['80px', '100px'],
        filter: 'unset'
      }
    },

    '.navMenuOpen': {
      color: 'white',
      backgroundColor: 'black',
      '.navItemDropdownMenu': {
        position: 'static',
        background: 'none'
      },
      '.socialContainer': {
        svg: {
          path: { fill: 'white' }
        }
      }
    }
  },

  footer: {
    borderTop: '3px solid',
    borderColor: 'primary',
    backgroundColor: 'black',
    padding: ['0rem', '0rem', '0rem', '0rem'],

    '.contactDetails-container': {
      alignItems: 'flex-start',
      textAlign: 'left'
    },

    '.socialContainer': {
      svg: {
        path: {
          fill: 'primary'
        }
      }
    },

    '.column': {
      padding: '2rem 4rem 2rem 2rem',
      backgroundColor: 'background',
      borderLeft: ['none', '', 'solid 3px'],
      borderColor: ['', '', 'primary'],
      alignItems: 'flex-start',
      textAlign: 'left'
    },
    '.logo': {
      filter: 'brightness(0) invert(1)',
      padding: '2rem'
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(0.6)'
    },
    '.copyright': {
      backgroundColor: 'transparent'
    },
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start']
    }
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    // borderBottom: '2px solid',
    borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['1.75rem', '', '2.5rem'],
    textTransform: 'uppercase',
    color: 'text',
    fontWeight: 'normal',
    fontFamily: 'heading',
    letterSpacing: '2px',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem'
  },

  subtitle: {
    color: 'black',
    fontWeight: '300',
    textTransform: 'initial',
    order: '1',
    fontFamily: 'subheading',
    fontSize: ['1rem', '1.5rem', '1.75rem', '2rem'],
    textTransform: 'uppercase'
  },

  text: {
    order: '3',
    color: '#202020',
    fontWeight: '300',
    p: {
      lineHeight: '1.75',
      fontWeight: '300'
    }
  },

  sideBySide1: {
    overflow: 'hidden',
    padding: ['0rem', '1rem', '', '2rem'],
    alignItems: ['', '', 'flex-start'],
    marginLeft: ['', '', '', '-4rem'],
    marginBottom: '3rem',
    '.content': {
      // order: ['', '', '2'],
      width: ['', '', '45%', '35%'],
      padding: ['2rem 0.5rem 2rem 3rem', '2rem 0.5rem 2rem 3rem', '2rem 0.5rem 2rem 3rem', '1.5rem 3.5rem', '4rem'],
      backgroundColor: 'white',
      position: ['', '', 'relative'],
      left: ['', '', '0%'],
      top: ['', '', '5rem'],
      // border: ['', '', 'solid 4px #c8c8c8'],
      // boxShadow: ['', '', '2px 2px 10px #202020'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        marginBottom: '2rem'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      // order: '1',
      width: ['', '', '55%', '65%'],

      height: ['', '', '80vh'],
      '::before': {
        background: 'unset'
      },
      img: {
        // height: '100vh',
        // border: ['solid 6px', 'solid 6px', 'solid 10px'],
        // borderColor: ['secondary', 'secondary', 'secondary'],
        // boxShadow: '2px 2px 10px black',
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    width: '100%',
    '.lazyload-wrapper': {
      width: ['', '', '60%'],
      height: ['', '', '60vh']
    },
    '.content': {
      // backgroundColor: '#d7d6cc',
      width: ['', '', '40%'],
      padding: [
        '2rem 0.5rem 2rem 3rem',
        '2rem 0.5rem 2rem 2rem',
        '2rem 0.5rem 2rem 2rem',
        '1.5rem 3.5rem',
        '2.5rem 4.5rem'
      ],
      alignItems: 'flex-start',
      textAlign: 'left',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        color: 'primary',
        marginBottom: '2rem'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  contentBlock: {
    padding: ['15vh 1rem', '', '20vh 1rem'],
    margin: ['0rem', '', '0rem'],
    '.section': {
      width: ['100%', '75%'],
      textAlign: 'left',
      alignItems: 'flex-start',
      margin: '0rem 0rem 0rem 10%'
      // textShadow: '2px 2px 10px black',
    },
    '.title': {
      variant: 'customVariants.title',
      width: '100%',
      border: 'none',
      textAlign: 'left',
      color: 'light',
      textTransform: 'capitalize',
      fontSize: ['1.5rem', '2rem', '3rem', '4rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontFamily: 'display',
      borderTop: '8px solid',
      borderColor: 'primary',
      paddingTop: '1.5rem'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      p: {
        color: 'white'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  contentBlock2: {
    variant: 'customVariants.contentBlock'
  },

  pageHeroSlider: {
    overflow: 'hidden',
    height: '80vh',
    '.slick-slider': {
      height: '80vh',
      '.slick-slide img': {
        height: '80vh'
      },
      '.slick-slide > div': {
        height: '80vh'
      },
      '.slick-arrow': {
        left: '1rem',
        bottom: '0rem',
        top: 'unset',
        height: '35px',
        width: '35px',
        padding: '0.25rem',
        backgroundColor: 'primary',
        color: 'white'
      },
      '.slick-prev': {},
      '.slick-next': {
        left: '4rem'
      }
    },

    '.hero_content_container': {
      marginBottom: 1,
      transformOrigin: 'center',
      transform: 'translate(50%, -50%) rotate(-90deg)',
      left: 'unset',
      top: '25%',
      right: '3.5rem',
      position: 'absolute',
      fontWeight: '300',
      fontSize: ['3rem', '', '4.5rem'],
      width: '100%',
      textTransform: 'uppercase',
      '.title': {
        color: 'white',
        textTransform: 'uppercase'
      }
    }
  },
  pageHeroSliderShout: {
    display: 'none'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.hero_content_container': {
      padding: ['2rem', '', '5rem 5rem 2rem'],
      maxWidth: 'unset',
      textAlign: 'center',
      alignItems: 'center',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      margin: '0rem',
      width: '90%'
    },
    '.logoHero': {
      order: '2',
      filter: 'brightness(0) invert(1)',
      opacity: '0.8',
      marginBottom: '3rem'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.25rem', '2.5rem', '3rem'],
      fontWeight: '300',
      width: '100%',
      order: '1',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
      order: '3',
      color: 'white',
      fontFamily: 'heading',
      marginBottom: '2rem'
    },
    '.text': {
      color: 'white',
      maxWidth: '600px',
      margin: '0rem auto'
    },
    a: {
      order: '4',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    },

    '.slick-initialized': {
      '.slick-slide > div': {
        overflow: 'hidden',
        position: 'relative'
      },
      '.slick-slide img': {
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        objectFit: 'cover',
        transform: 'translate(-50%, -50%)',
        filter: 'brightness(0.7)'
      },
      '.slick-dots': {
        bottom: '25px'
      },
      '.slick-prev:before, .slick-next:before': {
        color: 'white',
        fontSize: '25px'
      },

      '.slick-dots li button:before': {
        color: 'white',
        fontSize: '10px'
      },
      '.slick-next': {
        right: '5px',
        zIndex: '500'
      },
      '.slick-prev': {
        left: '0px',
        zIndex: '500'
      },

      'svg.slick-arrow': {
        width: '20px',
        color: 'primary'
      }
    }
  },
  homepageHeroShout: {
    display: 'none !important'
  },

  homepageShout: {
    '.containerPopUp': {
      backgroundColor: '#fff8f8',
      '.title': {
        color: 'primary'
      },
      '.text': {
        fontFamily: 'heading'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/c_scale,w_1900/sites/red-lotus/IMG_8022.jpg)',
    backgroundSize: 'cover',
    position: 'relative',

    margin: ['0rem', '0rem', '0rem', '0rem', '0rem'],
    '::before': {
      content: "''",
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '0rem',
      left: '0rem',
      backgroundColor: 'rgba(255,255,255,0.85)'
    },

    '.image3': {
      display: 'none'
    },

    '.imageContainer': {
      width: '100%',
      '.lazyload-wrapper': {
        zIndex: '10',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        img: {
          borderRadius: '500px',
          width: '40vw',
          height: '40vw',
          margin: '0rem'
        },
        '.image1': {
          transform: 'translateX(-35%)'
        },
        '.image3': {
          transform: 'translateX(35%)'
        }
      }
    },
    'div.content': {
      background: 'transparent',
      zIndex: '12',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%'
    },
    padding: [0, 0, 0, '10vh 1rem!important'],
    height: ['100vh', '']
  },

  homepageMenu: {
    variant: 'customVariants.contentBlock',
    position: 'relative',
    overflow: 'hidden',
    padding: '25vh 1rem',
    '::before': {
      content: "''",
      height: '102%',
      width: '102%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      background: 'linear-gradient(355deg,rgba(0,0,0,1) 0%,rgba(0,0,0,1) 12%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 100%)'
    },
    backgroundPosition: 'left center',
    backgroundSize: '100%',
    backgroundAttachment: ['', '', 'unset', 'unset'],
    height: 'auto',
    '.section': {
      zIndex: '10',
      margin: 'auto 5vw auto auto',
      '.subtitle': {
        color: 'white'
      }
    }
  },

  homepageDishes: {
    width: '100%',
    padding: ['3rem 1rem', '3rem 2rem', '3rem', '4rem'],
    maxWidth: 'unset',
    backgroundColor: 'black',
    color: 'white',
    '.innerMenuContainer': {
      margin: '0rem auto',
      position: 'relative'
    },
    '.slick-slider': {
      position: 'static',
      padding: '2rem 0rem 0rem',
      '.slick-arrow': {
        width: '40px',
        height: '40px',
        backgroundColor: 'secondaryDark',
        padding: '0.5rem',
        color: 'white',
        left: 'unset',
        bottom: 'unset',
        top: '0rem',
        right: '0rem',
        transform: 'unset',
        border: 'solid 1px',
        borderColor: 'primary',
        ':hover': {
          opacity: '0.7'
        }
      },
      '.slick-prev': {
        right: '3rem'
      },
      '.slick-next': {},
      '.slick-dots li.slick-active button:before': {
        color: 'secondaryDark'
      },

      '.slick-dots li button:before': {
        fontSize: '20px'
      }
    },
    '.menuSectionTitle': {
      width: '80%',
      textAlign: 'left',
      margin: '0rem 0rem 2rem',
      fontSize: ['2rem', '2.5rem', '2.75rem', '3rem'],
      color: '#806f6f',
      fontWeight: '300'
    },
    '.menuSectionDescription': {
      textAlign: 'left',
      color: '#cb1c06cc'
    },

    '.menuItemInnerContainer': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      '.menuItemImageContainer': {
        width: '30%',
        img: {
          borderRadius: '0px',
          padding: '1rem',
          border: 'solid 1px',
          borderColor: 'primary'
        }
      },

      '.menuItemContentContainer': {
        width: '70%',
        padding: ['2rem', '', '3rem', '4rem'],
        '.menuItemName': {
          fontSize: ['2rem', '', '3rem'],
          fontWeight: '200',
          flexShrink: 8
        },
        '.menuItemDescription': {
          fontSize: '1.1rem',
          fontWeight: '200',
          opacity: '0.8',
          color: 'white'
        },
        '.menuItemPrice': {
          color: 'primary',
          fontWeight: 'bold'
        }
      }
    }
  },

  homepageMenuBoxes: {
    padding: '1.5rem 1.5rem',
    margin: ['-35vh 1rem 3rem'],
    backgroundColor: ['#d0cec7', 'transparent', '', ''],
    zIndex: '10',
    '.box': {
      backgroundColor: '#2f2929',
      color: 'white',
      margin: ['0.5rem', '0.75rem', '1rem', '1.5rem'],
      // boxShadow: '2px 4px 10px black',
      width: ['calc(100% - 1rem)', 'calc(50% - 1.5rem)', 'calc(33.3% - 2rem)', 'calc(25% - 3rem)'],
      '.image': {
        height: '100%',
        minHeight: '300px',
        objectFit: 'cover',
        display: 'none'
      },
      ':hover': {
        backgroundColor: 'black',
        color: 'white'
      }
    }
  },

  homepageQuote: {
    variant: 'customVariants.contentBlock'
  },
  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageTeam: {
    variant: 'customVariants.contentBlock2',
    margin: ['2rem 0rem', '2rem 0rem', '2rem', '2rem'],
    width: ['', '', 'calc(100% - 4rem)'],
    minHeight: ['60vh', '', '', '90vh'],
    '.section': {
      width: '75%',
      textAlign: 'left',
      alignItems: 'flex-start',
      margin: ['', '', '0rem 0rem 0rem 10%'],
      // textShadow: '2px 2px 10px black',
      backgroundColor: ['', '', 'white'],
      padding: ['', '', '3rem 4rem'],
      height: 'fit-content',
      left: '0rem',
      margin: ['', '', '0rem auto 0rem -4rem']
    },
    '.title': {
      variant: 'customVariants.title',
      width: '100%',
      border: 'none',
      textAlign: 'left',
      color: ['white', '', 'black'],

      textTransform: 'capitalize',
      fontSize: ['1.5rem', '2rem', '3rem', '4rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontFamily: 'display',
      borderTop: '8px solid',
      borderColor: 'primary',
      paddingTop: '1.5rem',
      color: '#cfa46b'
    },
    '.text': {
      variant: 'customVariants.text',
      color: ['white', '', 'black'],

      p: {
        color: ['white', '', 'black']
      }
    }
  },

  homepagePrivateParties: {
    variant: 'customVariants.sideBySide2',
    marginTop: '2rem'
  },
  homepageGiftCards: {
    variant: 'customVariants.sideBySide2'
  },

  homepageLocation: {
    variant: 'customVariants.sideBySide1'
  },

  homepageSectionBoxes: {
    padding: '0rem 1.5rem 1.5rem',
    margin: ['', ' 0rem 0rem'],
    zIndex: '10',
    '.contentBoxes': {
      width: '100%'
    },
    '.box': {
      backgroundColor: 'white',
      margin: ['0.5rem', '1rem', '0.5rem', '1rem'],
      // boxShadow: '2px 4px 10px black',
      padding: ['0.5rem', '', '', '0.5rem', '1rem'],
      transition: 'all ease-in-out 0.8s',
      width: ['calc(100% - 1rem)', 'calc(100% - 2rem)', 'calc(50% - 1rem)', 'calc(33.3% - 2rem)'],
      '.image': {
        height: '100%',
        minHeight: '300px',
        height: '300px',
        objectFit: 'cover',
        marginBottom: '1.5rem'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontSize: ['1.5rem', '1.5rem', '1.5rem', '1.5rem'],
        color: 'primary',
        order: 'unset'
      },
      ':hover': {
        backgroundColor: '#efeee6'
        // color: 'white',
      }
    }
  },

  homepageContact: {
    variant: 'customVariants.contentBlock',
    'div.section': {
      '.subtitle': {
        color: 'white'
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    margin: '0rem 0rem',
    maxWidth: 'unset',
    padding: ['2rem 1rem', '', '3rem 2rem'],
    // backgroundImage:
    //   'url(https://res.cloudinary.com/gonation/image/upload/sites/cast-iron-chef-kitchen-bar/pattern-bg.png)',
    // backgroundColor: '#fff8f8',
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',
    '.menuItemName': {
      flexShrink: '8!important',
      'flex-shrink': '8!important'
    },

    '.menuItemName, .menuItemPrice, .menuSectionTitle': {},
    '.backToMenuBtn': {
      variant: 'buttons.secondary',
      margin: '0rem auto 2rem 0rem'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {
      width: ['100%', '', 'calc(50% - 1rem)'],
      borderRadius: '0px',
      position: 'relative'
    },
    '.allInContainerWrapper ': {
      maxWidth: '1200px',
      margin: '0rem auto'
    },
    '.cellImageContainer': {
      overflow: 'hidden'
    },
    '.cellImage': {
      borderRadius: '0px',
      transition: 'all ease-in-out 0.5s',
      ':hover': {
        transform: 'scale(1.1) translate(-50%, -50%)',
        transformOrigin: 'center center'
      }
    },
    '.menuCell': {
      borderRadius: '0px',
      '.imageFill': {
        paddingBottom: '60%'
      }
    },

    '.cellName': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'light',
      margin: '0rem',
      fontSize: ['1.5rem', '', '1.75rem', '2.5rem']
    },

    '.tabsRow': {
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      borderBottom: 'solid 2px black',
      borderTop: 'solid 2px black',
      marginBottom: ['2.5', '', '3.5rem'],
      padding: '1rem 1.5rem 1.5rem',
      '::before': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '3rem'],
        content: "'The Menu'",
        margin: ['0rem 0rem 1.5rem', '', '0rem auto 0rem 0rem'],
        width: ['100%', '', '', 'fit-content'],
        letterSpacing: '0px',
        textTransform: 'capitalize',
        padding: '1rem 0rem',
        fontFamily: 'heading',
        color: '#ff1616',
        textTransform: 'uppercase'
      },
      '.menuTab': {
        fontSize: '0.9rem',
        border: 'none',
        textTransform: 'capitalize',
        padding: '0.25rem',
        letterSpacing: '-1px',
        fontFamily: 'heading',
        height: 'fit-content',
        fontWeight: '300',
        backgroundColor: 'transparent',
        margin: 'auto 0.25rem',
        ':hover': {
          color: 'primary'
        }
      },
      '.menuTabActive': {
        color: 'text',
        borderBottom: '1px solid black'
      }
    },

    '.menuContainer': {
      paddingBottom: '0rem',
      padding: '1rem 0rem',
      borderBottom: 'solid 1px'
    },

    '.menuSectionTitle': {
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      fontSize: ['1.75rem', '2rem', '2.25rem', '2.5rem'],
      width: '100%',

      letterSpacing: '2px',
      textTransform: 'uppercase'
      // textTransform: 'uppercase',
    },
    '.menuSectionDescription': {
      textAlign: 'left',
      opacity: '0.75',
      fontSize: '0.9rem',
      color: 'text',
      marginBottom: 'rem'
    },

    '.menuItemContainer, .menuItemContainerImgActive': {
      margin: '0rem 0rem 1rem',
      padding: ['0rem', '', '0rem 1.5rem 0rem 0rem']
    },

    '.menuItemInnerContainer': {
      margin: '0rem'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },

    '.menuItemName': {
      marginBottom: '0rem',
      fontFamily: 'heading',
      color: 'text',
      fontSize: ['1rem', '1rem', '1.1rem', '1.2rem']
    },
    '.menuItemDescription': {
      color: 'text',
      textTransform: 'lowercase'
    }
  },

  beerMenu: {
    backgroundColor: 'black',
    color: 'white',
    padding: '1rem'
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        // filter: 'brightness(1) invert(1)',
      }
    },
    '.eventItemImage': {
      minHeight: 'unset',
      maxHeight: 'unset',
      height: '100%'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '5rem 1rem',
    order: '4',
    border: ['solid 10px black', 'solid 10px black', 'solid 15px black'],
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'primary',
      marginBottom: '2rem',
      variant: 'customVariants.title',
      color: 'white'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem',
      color: 'white'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid',
        borderColor: 'primary',
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: 'white',
        '::placeholder': {
          color: 'white'
        }
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid',
        borderColor: 'primary',
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: 'white',
        '::placeholder': {
          color: 'white'
        }
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'primary',
    color: 'light',
    '.content_container': {
      border: 'solid 10px',
      borderColor: 'secondary'
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      color: 'white',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    },
    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.box': {
      backgroundColor: 'secondary',
      margin: ['1rem', '', '2rem'],
      width: ['100%', 'calc(50% - 0.5rem * 2)', 'calc(33.3% - 2rem * 2)']
    },
    '.imageContainer': {
      backgroundColor: 'black',
      img: {
        objectFit: 'contain'
      }
    },
    '.title': {
      color: 'light',
      borderBottom: '2px solid #cababa'
    },
    '.subtitle': {
      color: 'light'
    }
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {},

  // ? ==========================
  // ? =====  Reservations  =====
  // ? ==========================

  reservations: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10vh 1rem',
    '.textContent': {
      display: 'none'
    }
  }
}
