export default {
  heading: 'Playfair Display, serif',
  subheading: 'Source Sans Pro, sans-serif',
  body: 'Roboto, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Caveat, cursive',
  googleFonts: [
    'Roboto:100,200,300,400,500,600,700,800,900',
    'Playfair Display:400,500,600,700,800,900',
    'Caveat:400,500,600,700',
    'Source Sans Pro:100,200,300,400,500,600,700,800,900'
  ],
  customFamilies: ['Din'],
  customUrls: ['https://www.gonation.biz/fonts/din/din.css']
}
